import React, { useState, useEffect } from "react"
import styled from "styled-components"
import ScrollTo from "react-scroll-into-view"
import { MEDIA_MIN_MEDIUM } from "../../constants"

const Button = styled.button`
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0.8rem;
  right: 1rem;
  cursor: pointer;
  border: none;
  padding: 0;
  opacity: ${(p) => (p.show ? "1" : "0")};
  transition: opacity 0.2s;
  background: ${(p) => p.theme.colors.secondaryOpac};
  color: ${(p) => p.theme.colors.white};
  font-size: 2rem;

  ${MEDIA_MIN_MEDIUM} {
    right: 1rem;
  }

  &:hover {
    opacity: 0.8;
  }
`

export default () => {
  const [show, setShow] = useState(false)

  const handleScroll = () => {
    const top = window.pageYOffset || document.documentElement.scrollTop
    setShow(top > 100)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <ScrollTo selector=".top">
      <Button show={show} aria-label="Go to top of page">
        <i className="fal fa-long-arrow-up"></i>
      </Button>
    </ScrollTo>
  )
}
