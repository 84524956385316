import React from "react"
import styled from "styled-components"

const Button = styled.button`
  height: 5rem;
  width: 100%;
  cursor: pointer;
  border: none;
  padding: 0.4rem 2.5rem 0 2.5rem;
  transition: background 0.2s;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;

  &:focus {
    outline: none;
  }

  &:hover {
    background: ${(p) => p.theme.colors.secondaryOpac};
  }
`

export default ({ children, ...other }) => (
  <Button {...other}>{children}</Button>
)
