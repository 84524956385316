import React, { useContext } from "react"
import styled from "styled-components"
import Slider from "react-slick"
import { usePage } from "../context/PageContext"
import { BrinkContext } from "../context/BrinkContext"

const Container = styled.div`
  height 100%;

  .slick-slider {
    height: 3.9rem;
    display: flex;
    align-items: center;

    div {
      &:focus {
        outline: none;
      }
    }
  }

  .slick-slide {
    text-align: center;
  }
`

const Usp = styled.div`
  text-transform: uppercase;
  height 100%;
  padding-top: 0.1rem;
  font-size: 1.2rem;

  i {
    font-size: 1.6rem;
    margin-right: 0.8rem;
    color: ${(p) =>
      p.inverted ? p.theme.colors.secondary : p.theme.colors.white};
  }
`

export default ({ inverted }) => {
  const { sanityUsp } = usePage()
  const { languageCode } = useContext(BrinkContext)

  if (!sanityUsp) return null

  const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    draggable: false,
    vertical: true,
    autoplaySpeed: 8000
  }

  return (
    <Container>
      <Slider {...settings}>
        {sanityUsp.uspEntries.map((entry) => (
          <Usp key={entry._key} inverted={inverted}>
            <i className="fal fa-check"></i>
            {entry[languageCode] || entry.en}
          </Usp>
        ))}
      </Slider>
    </Container>
  )
}
