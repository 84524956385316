import React, { useContext } from "react"
import styled from "styled-components"
import { BrinkContext } from "../context/BrinkContext"
import Addons from "./Addons"
import Dinero from "dinero.js"

const Wrapper = styled.div`
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.primaryLight};
  padding: 2rem 2rem 2rem 0;
`

const Container = styled.div`
  width: 100%;
  position: relative;
  color: ${(p) => p.theme.colors.black};
  display: flex;
  align-items: center;
`

const Name = styled.div`
  margin: 0 0 0.8rem;
  text-transform: uppercase;
  font-size: 1.3rem;
`

const Description = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.1rem;
`

const Price = styled.div`
  display: flex;
  font-size: 1.4rem;
  margin-top: 1rem;

  span {
    margin-right: 0.4rem;
  }
`

const CurrentPrice = styled.div``

const OriginalPrice = styled.div`
  margin-left: 1rem;
  color: ${(p) => p.theme.colors.darkGrey};
  text-decoration: line-through;
`

const ChangeQty = styled.div`
  position: absolute;
  top: 35%;
  right: 0;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors.white};
`

const Qty = styled.div`
  width: 2.6rem;
  height: 2.6rem;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(p) => p.theme.colors.secondary};
`

const QtyButton = styled.button`
  cursor: pointer;
  color: ${(p) => p.theme.colors.white};
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: ${(p) => p.theme.colors.secondary};
  font-size: 1.2rem;
  transition: background 0.2s;

  &:hover {
    background: ${(p) => p.theme.colors.secondaryOpac};
  }
`

const Delete = styled.span`
  position: absolute;
  top: 55%;
  margin-top: -1.5rem;
  left: -1.5rem;
  background: ${(p) => p.theme.colors.secondaryOpac};
  color: ${(p) => p.theme.colors.white};
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: ${(p) => p.theme.colors.secondary};
  }

  i {
    &:focus {
      outline: none;
    }
  }
`

const Image = styled.img`
  max-width: 7rem;
  display: block;
  margin-right: 2rem;
`

export default ({ item, showControls, ...other }) => {
  if (item.type === "shippingOption") return false
  const { id, rowId, imageUrl, name, quantity, description } = item
  const {
    cart,
    currentStore,
    plusOneProductVariantToCart,
    minusOneProductVariantToCart,
    removeProductFromCart,
    isLoading,
    setIsLoading
  } = useContext(BrinkContext)

  const discount = cart.discounts.productDiscounts.find((d) => d.rowId === rowId)
  const priceDinero = toDinero(item.price[currentStore.currencyUnit], currentStore.currencyUnit)
  let discountDinero = null
  if (discount) {
    discountDinero = toDinero(discount.discountAmount[currentStore.currencyUnit] || 0, currentStore.currencyUnit)
  }
  const price = discountDinero ? priceDinero.subtract(discountDinero).toFormat() : null
  const originalPrice = priceDinero.toFormat()

  return (
    <Wrapper>
      <Container {...other}>
        <Image src={imageUrl}></Image>
        {showControls && !item.isGift && (
          <Delete>
            <i
              onClick={() => {
                if (isLoading) return false
                setIsLoading(true)
                removeProductFromCart(id, rowId)
              }}
              onKeyDown={(event) => {
                if (isLoading) return false
                if (event.keyCode !== 13) return false
                removeProductFromCart(id, rowId)
                setIsLoading(true)
              }}
              className="fal fa-trash-alt"
              role="button"
              aria-label="Remove product from cart"
              tabIndex={0}
            ></i>
          </Delete>
        )}
        <div>
          <Name>{name}</Name>
          <Description>{description}</Description>
          <Price>
            <span>{quantity} x </span>
            {price && item.isGift ? (
              <CurrentPrice>{price}</CurrentPrice>
            ) : price ? (
              <>
                <CurrentPrice>{price}</CurrentPrice>
                <OriginalPrice>{originalPrice}</OriginalPrice>
              </>
            ) : (
              <CurrentPrice>{originalPrice}</CurrentPrice>
            )}
          </Price>
        </div>
        {showControls && !item.isGift && (
          <ChangeQty>
            <QtyButton
              onClick={() => {
                if (isLoading) return false
                setIsLoading(true)
                minusOneProductVariantToCart(id, rowId)
              }}
              onKeyDown={(event) => {
                if (isLoading) return false
                if (event.keyCode !== 13) return false
                minusOneProductVariantToCart(id, rowId)
                setIsLoading(true)
              }}
              tabIndex={0}
            >
              <i className="fal fa-minus"></i>
            </QtyButton>
            <Qty>{quantity}</Qty>
            <QtyButton
              onClick={() => {
                if (isLoading) return false
                plusOneProductVariantToCart(id, rowId)
                setIsLoading(true)
              }}
              onKeyDown={(event) => {
                if (isLoading) return false
                if (event.keyCode !== 13) return false
                plusOneProductVariantToCart(id, rowId)
                setIsLoading(true)
              }}
              tabIndex={0}
            >
              <i className="fal fa-plus"></i>
            </QtyButton>
          </ChangeQty>
        )}
      </Container>
      <Addons addons={item.addons} discounts={cart.discounts} showControls={true} />
    </Wrapper>
  )
}

const toDinero = (amount, currencyUnit) => {
  return new Dinero({ amount: amount, currency: currencyUnit })
}
