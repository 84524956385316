import React, { useContext } from "react"
import Helmet from "react-helmet"
import i18n from "i18next"
import { useStaticQuery, graphql } from "gatsby"
import { getKlavyioApiKey } from "./context/utils/klaviyoEvents"
import { BrinkContext } from "./context/BrinkContext"

const Head = ({ description, meta = [], title, url }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaTitle = title || site.siteMetadata.title
  const metaDescription = description || site.siteMetadata.description
  const lang = i18n.language || window.localStorage.i18nextLng
  const { currentStore } = useContext(BrinkContext)

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={metaTitle}
      titleTemplate={`${site.siteMetadata.title} | %s`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: metaTitle
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author
        },
        {
          name: `twitter:title`,
          content: metaTitle
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ].concat(meta)}
    >
      <link rel="preconnect" href="https://cdnjs.cloudflare.com/" />
      <link rel="preconnect" href="https://chimpstatic.com/" />
      <link rel="preconnect" href="https://kit.fontawesome.com/" />
      <link rel="preconnect" href="https://kit-pro.fontawesome.com/" />
      <link rel="preconnect" href="https://fonts.googleapis.com/" />
      <link rel="preconnect" href="https://www.googletagmanager.com/" />
      <link rel="preconnect" href="https://www.google-analytics.com" />

      <link
        href="https://fonts.googleapis.com/css2?family=Josefin+Sans:ital@0;1&family=Playfair+Display:ital@0;1&display=swap"
        rel="stylesheet"
        type="text/css"
      />
      <link rel="canonical" href={url} />
      <script src="https://kit.fontawesome.com/9c2b3f2fd5.js" rel="preload" as="font"></script>

      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />

      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />

      <script
        id="klavio-snippet"
        key="klavio-snippet"
        src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${getKlavyioApiKey(currentStore.countryCode)}`}
        type="text/javascript"
        async
      />
    </Helmet>
  )
}
export default Head
