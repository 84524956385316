import React from "react"
import { Link as LinkUI } from "gatsby"
import styled, { css } from "styled-components"

const GatsbyLink = ({
  children,
  to,
  underline,
  inverted,
  color,
  sticky,
  ...other
}) => (
  <LinkUI to={to} {...other}>
    {children}
  </LinkUI>
)

const Link = styled(GatsbyLink)`
  text-decoration: none;
  cursor: pointer;
  position: relative;
  color: ${p => p.color || "white"};

  ${p =>
    p.underline &&
    css`
      &:after {
        content: "";
        position: absolute;
        height: 1px;
        left: 0;
        right: 0;
        bottom: -0.3rem;
        background-color: ${p => (p.inverted ? "transparent" : p.color)};
        transition: background-color 0.2s;
      }

      &:hover {
        &:after {
          background-color: ${p => (p.inverted ? p.color : "transparent")};
        }
      }
    `}
`

export default Link
