import React, { useContext } from "react"
import styled, { ThemeContext } from "styled-components"
import { useTranslation } from "react-i18next"
import { containerMaxWidth, MEDIA_MIN_X_LARGE } from "../../constants"
import Link from "../Link"
import Logo from "../../images/brink-logo.svg"
import GoToTopButton from "../ui/GoToTopButton"
import PaymentLogos from "../../icons/logos/payment.svg"
import ShippingLogos from "../../icons/logos/shipping.svg"

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 3rem 5rem;
  justify-content: center;
  color: ${(p) => p.theme.colors.white};
  font-size: 1.1rem;
  background-color: ${(p) => p.theme.colors.secondary};
  text-transform: uppercase;
  text-align: center;
  line-height: 1.8rem;
  position: relative;

  ${MEDIA_MIN_X_LARGE} {
    height: 6rem;
    padding: 0;
    padding-top: 0.4rem;
    align-items: center;
    flex-wrap: nowrap;
  }
`

const PaymentAndShippingProviders = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  svg {
    height: 4rem;
  }

  ${MEDIA_MIN_X_LARGE} {
    position: absolute;
    justify-content: flex-start;
    max-width: ${containerMaxWidth};
    width: 100%;
    margin-top: 0;

    i {
      margin: 0 1rem 0 0;
    }
  }
`

const CoockieLink = styled(Link)`
  margin-left: 0.4rem;
`

const HomeLink = styled(Link)`
  display: block;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 2rem;

  ${MEDIA_MIN_X_LARGE} {
    display: none;
  }

  svg {
    max-width: 8rem;
    padding: 3rem 0 1rem;
    fill: ${(p) => p.theme.colors.white};
  }
`

const Separator = styled.span`
  display: none;

  ${MEDIA_MIN_X_LARGE} {
    margin: 0 1rem 0.5rem;
    border-right: 0.1rem solid;
    display: inline-block;
    height: 1.5rem;
  }
`

const LogoSeparator = styled(Separator)`
  display: block;
  width: 100%;

  ${MEDIA_MIN_X_LARGE} {
    display: inline-block;
    height: 2.2rem;
    margin: 0 2rem;
    width: auto;
  }
`

export default () => {
  const { t } = useTranslation("translations")
  const { colors } = useContext(ThemeContext)

  return (
    <Container>
      <PaymentAndShippingProviders>
        <PaymentLogos />
        <LogoSeparator />
        <ShippingLogos />
      </PaymentAndShippingProviders>
      <HomeLink to="/" title="Go to start page">
        <Logo alt="Brink Demo shop logo" />
      </HomeLink>
      <span>© 2020 BRINK DEMO</span>
      <Separator />
      <span>
        {t("We use cookies")} -
        <CoockieLink to="/terms-and-conditions/" underline color={colors.white}>
          {t("Read more here")}
        </CoockieLink>
      </span>
      <GoToTopButton />
    </Container>
  )
}
