import React, { useContext } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { BrinkContext } from "../context/BrinkContext"
import Price from "../product/Price"

const Totals = styled.div`
  width: 100%;
`

const Row = styled.div`
  border-top: 0.1rem solid ${(p) => p.theme.colors.primaryLight};
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  height: 4.4rem;
  justify-content: space-between;
  text-transform: uppercase;
  margin: 0;
  font-size: 1.2rem;
  padding-top: 0.4rem;

  &:last-child {
    font-size: 1.4rem;
  }
`

const LinePrice = styled(Price)`
  p {
    font-size: 1.2rem;
    margin: 0;
  }
`

const DiscountPrice = styled(LinePrice)`
  &:before {
    content: "-";
    margin-right: 0.2rem;
  }
`

const Total = styled(Price)`
  p {
    margin: 0;
  }
`

export default ({ hideShipping, checkoutPrice, ...rest }) => {
  const { t } = useTranslation("translations")
  const { cart, languageCode, currentStore } = useContext(BrinkContext)
  const shipping = cart.shipping || cart.cartItems.find((item) => item.type === "shippingOption")

  return (
    <Totals {...rest}>
      {cart.totalDiscountAmount !== 0 && (
        <Row>
          <span>{t("Discount")}</span>
          <DiscountPrice
            price={[
              {
                amount: cart.totalDiscountAmount ?? 0,
                currencyUnit: currentStore.currencyUnit
              }
            ]}
          />
        </Row>
      )}
      {!hideShipping && shipping && (
        <Row>
          <span>
            {t("Shipping")} ({shipping.attribute?.displayName[languageCode] ?? shipping.name})
          </span>
          <LinePrice
            checkoutPrice={checkoutPrice}
            price={[
              {
                amount: shipping.price[currentStore.currencyUnit] ?? 0,
                currencyUnit: currentStore.currencyUnit
              }
            ]}
            allDiscount={[
              {
                amount: shipping?.discount?.[currentStore.currencyUnit] ?? 0,
                currencyUnit: currentStore.currencyUnit
              }
            ]}
          />
        </Row>
      )}
      <Row>
        <span>{t("Grand total")}</span>
        <Total
          price={[
            {
              amount: cart.totalPriceWithDiscount || 0,
              currencyUnit: currentStore.currencyUnit
            }
          ]}
        />
      </Row>
    </Totals>
  )
}
