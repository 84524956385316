import React, { useContext } from "react"
import styled from "styled-components"
import Countdown, { zeroPad } from "react-countdown"
import { MEDIA_MIN_MEDIUM, MEDIA_MAX_MEDIUM, MEDIA_MIN_LARGE } from "../../constants"
import { BrinkContext } from "../context/BrinkContext"

const Container = styled.div`
  padding: 1rem 2rem 1.2rem;
  width: 100%;
  margin: 0 auto;
  background: ${(p) => (p.inHeader ? p.theme.colors.background : "none")};
  color: ${(p) => p.theme.colors.black};
  z-index: 100;
  position: ${(p) => (p.inHeader ? "fixed" : "relative")};
  left: 0;
  top: 0;
  height: 4.5rem;
  display: flex;
  justify-content: center;
  border-bottom: 0.1rem solid ${(p) => (p.inHeader ? "transparent" : p.theme.colors.lightBorder)};

  ${MEDIA_MAX_MEDIUM} {
    display: ${(p) => (p.inHeader ? "none" : "flex")};
  }

  ${MEDIA_MIN_LARGE} {
    background: none;
    width: ${(p) => (p.inHeader ? "auto" : "100%")};
    position: relative;
    padding: 1.5rem 0;
    color: ${(p) => (p.inverted || !p.inHeader ? p.theme.colors.black : p.theme.colors.white)};
    margin: 0 0 0.4rem;
  }
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  i {
    margin-left: 3rem;
    font-size: 2rem;
    padding-top: 0.2rem;
  }
`

const Title = styled.span`
  font-size: 1.3rem;
  text-transform: uppercase;
  margin: 0 auto;
  text-align: center;
  border-left: 0.3rem solid ${(p) => p.theme.colors.primary};
  padding-left: 1rem;
  line-height: 1.4rem;

  span {
    font-size: 1.3rem;
    font-weight: 600;
    margin-left: 0.5rem;
  }

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.3rem;

    span {
      font-size: 1.3rem;
    }
  }
`

const Qty = styled.div`
  font-size: 1rem;
  text-transform: uppercase;
  padding-top: 0.3rem;
  width: 100%;
`

export default ({ inHeader, inverted, cartQuantity, cartLimit, props }) => {
  const { cart, isCartClosed, closeCart } = useContext(BrinkContext)
  if (isCartClosed() || !cart.cartItems.length) return null
  const expiryDateTime = cart.expiryDateTime
  if (!expiryDateTime) return null

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return "Your cart expired"
    } else {
      return (
        <>
          Your cart is reserved for:
          <span>
            {zeroPad(minutes)}:{zeroPad(seconds)}
          </span>
        </>
      )
    }
  }

  return (
    <Container inverted={inverted} inHeader={inHeader} {...props}>
      <Content>
        <Title>
          <Countdown onComplete={() => closeCart()} renderer={renderer} date={Date.parse(expiryDateTime)} />
        </Title>
        {cartLimit && (
          <Qty>
            {cartQuantity === cartLimit ? (
              <>Cart limit reached</>
            ) : (
              <>
                Products: {cartQuantity}/{cartLimit}
              </>
            )}
          </Qty>
        )}
      </Content>
    </Container>
  )
}
