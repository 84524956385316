import Dinero from "dinero.js"
import axios from "axios"
import createDispatcher from "./createDispatcher"

const dispatcher = createDispatcher("klaviyo")

export const addedToCart = (cart, addedId, quantity, currentStore) => {
  try {
    const _learnq = dispatcher(window._learnq)
    const addedProduct = cart.cartItems.find((p) => p.id === addedId)
    if (addedProduct.type !== "productVariant") return

    const klavioCart = {
      $value: toDinero(cart.totalPriceWithDiscount, currentStore.currencyUnit).toUnit(),
      AddedItemProductName: addedProduct.name,
      AddedItemProductID: addedProduct.id,
      AddedItemImageURL: addedProduct.imageUrl,
      AddedItemPrice: toDinero(addedProduct.price[currentStore.currencyUnit], currentStore.currencyUnit).toUnit(),
      AddedItemQuantity: quantity,
      CountryCode: currentStore.countryCode,
      ItemNames: cart.cartItems.map((ct) => ct.name),
      CheckoutURL: `${window.location.origin}/checkout`,
      Items: toKlaviyoItems(cart.cartItems, currentStore.currencyUnit)
    }

    const resp = _learnq.push(["track", "Added to Cart", klavioCart])
    console.debug("Set klaviyo added to cart response: ", resp)
  } catch (error) {
    console.error(error)
  }
}

export const identify = (address) => {
  try {
    const _learnq = dispatcher(window._learnq)
    const resp = _learnq.push([
      "identify",
      {
        $email: address.email,
        $first_name: address.firstName,
        $last_name: address.lastName
      }
    ])
    console.debug("Set klaviyo identity response: ", resp)
  } catch (error) {
    console.error(error)
  }
}

export const startedCheckout = (cart, currentStore, token) => {
  try {
    const _learnq = dispatcher(window._learnq)
    const cartProuductVariants = cart.cartItems.filter((i) => i.type === "productVariant")
    const resp = _learnq.push([
      "track",
      "Started Checkout",
      {
        $event_id: `${cart.id}_${new Date().valueOf()}`,
        $value: toDinero(cart.totalPriceWithDiscount, currentStore.currencyUnit).toUnit(),
        CountryCode: currentStore.countryCode,
        ItemNames: cartProuductVariants.map((ct) => ct.name),
        CheckoutURL: `${window.location.origin}/checkout/?cart=${token}`,
        Items: toKlaviyoItems(cartProuductVariants, currentStore.currencyUnit)
      }
    ])
    console.debug("Set klaviyo started checkout response: ", resp)
  } catch (error) {
    console.error(error)
  }
}

const orderedProducts = (billingAddress, orderId, cartItems, currencyUnit, _learnq) => {
  try {
    const orderedProductEvents = cartItems
      .filter((p) => p.type === "productVariant")
      .map((item) => [
        "track",
        "Ordered Product",
        {
          customer_properties: {
            $email: billingAddress.email,
            $first_name: billingAddress.firstName,
            $last_name: billingAddress.lastName,
            $country: billingAddress.country
          },
          properties: {
            $event_id: orderId,
            OrderId: orderId,
            $value: toDinero(item.price[currencyUnit], currencyUnit).toUnit(),
            ProductID: item.id,
            ProductName: item.name,
            Quantity: item.quantity,
            ImageURL: item.imageUrl,
            Categories: [item.category],
            ProductURL: `${window.location.origin}/product/${item.slug}`
          }
        }
      ])
    const result = orderedProductEvents.map((orderedProductEvent) => _learnq.push(orderedProductEvent))
    console.debug("Set klaviyo orderedProducts response: ", result)
  } catch (error) {
    console.error(error)
  }
}

export const placedOrder = (billingAddress, currentStore, order, discountCode, cartItems) => {
  try {
    const _learnq = dispatcher(window._learnq)
    const event = [
      "track",
      "Placed Order",
      {
        customer_properties: {
          $email: billingAddress.email,
          $first_name: billingAddress.firstName,
          $last_name: billingAddress.lastName,
          $phone_number: billingAddress.phone,
          $address1: billingAddress.address,
          $address2: billingAddress.houseNumberOrName || "",
          $city: billingAddress.city,
          $zip: billingAddress.postalCode,
          $region: "",
          $country: billingAddress.country
        },
        properties: {
          $event_id: order.id,
          $value: toDinero(order.orderAmountWithDiscount, currentStore.currencyUnit).toUnit(),
          CountryCode: currentStore.countryCode,
          DiscountCode: discountCode || "",
          DiscountValue: toDinero(order.orderAmount, currentStore.currencyUnit)
            .subtract(toDinero(order.orderAmountWithDiscount, currentStore.currencyUnit))
            .toUnit(),
          ItemNames: cartItems.map((ct) => ct.name),
          Items: toKlaviyoItems(cartItems, currentStore.currencyUnit)
        },
        BillingAddress: toKlavioAddress(billingAddress),
        ShippingAddress: toKlavioAddress(billingAddress)
      }
    ]
    const resp = _learnq.push(event)
    orderedProducts(billingAddress, order.id, cartItems, currentStore.currencyUnit, _learnq)
    console.debug("Set klaviyo placedOrder response: ", resp)
  } catch (error) {
    console.error(error)
  }
}

export const viewProduct = (product, languageCode) => {
  try {
    const _learnq = dispatcher(window._learnq)
    const resp = _learnq.push([
      "track",
      "Viewed Product",
      {
        ProductName: product.title,
        ProductID: product._id,
        LanguageCode: languageCode
      }
    ])
    console.debug("Set klaviyo Viewed Product response: ", resp)
  } catch (error) {
    console.error(error)
  }
}

const toKlavioAddress = (address) => {
  return {
    email: address.email,
    first_name: address.firstName,
    last_name: address.lastName,
    phone_number: address.phone,
    address1: address.address,
    address2: address.houseNumberOrName || "",
    city: address.city,
    zip: address.postalCode,
    region: "",
    country: address.country
  }
}

const toKlaviyoItems = (items, currencyUnit) => {
  return items.map((item) => ({
    ProductID: item.id,
    ProductName: item.name,
    Quantity: item.quantity,
    ItemPrice: toDinero(item.price[currencyUnit], currencyUnit).toUnit(),
    RowTotal: toDinero(item.price[currencyUnit], currencyUnit).multiply(item.quantity).toUnit(),
    ImageURL: item.imageUrl,
    ProductURL: `${window.location.origin}/product/${item.slug}`
  }))
}

const toDinero = (amount, currencyUnit) => {
  return new Dinero({ amount: amount, currency: currencyUnit })
}

export const getKlavyioApiKey = (countryCode) => {
  try {
    const env = process.env.GATSBY_KLAVIYO_ENVIRONMENT || "test"
    const klavioyApiKeys = {
      test: {
        SE: "Rpii3c",
        NL: "Rpii3c",
        DE: "Rpii3c",
        AT: "Rpii3c",
        GB: "Rpii3c",
        BE: "Rpii3c"
      },
      production: {
        SE: "W364Kb",
        NL: "TSZGkE",
        DE: "XfRWuv",
        AT: "XfRWuv",
        GB: "UUVjhW",
        BE: "TSZGkE"
      }
    }
    const key = klavioyApiKeys[env][countryCode] || ""
    if (!key) console.error("Missing Klaviyo Apikey")
    return key
  } catch (error) {
    console.error("Missing Klaviyo Apikey")
    console.error(error)
  }
}

export const getKlavyioListIds = (countryCode) => {
  try {
    const env = process.env.GATSBY_KLAVIYO_ENVIRONMENT || "test"
    const klavioyListIds = {
      test: {
        default: "TXbeMh",
        SE: "TXbeMh",
        NL: "TXbeMh",
        DE: "TXbeMh",
        AT: "TXbeMh",
        GB: "TXbeMh",
        BE: "TXbeMh"
      },
      production: {
        default: "USYVFZ",
        SE: "UmTTzY",
        NL: "WUWAdK",
        DE: "YhabWx",
        AT: "YhabWx",
        GB: "UPMrsL",
        BE: "XXeUwh"
      }
    }
    const key = klavioyListIds[env][countryCode] || klavioyListIds[env].default
    if (!key) console.error("Missing Klaviyo List ID")
    return key
  } catch (error) {
    console.error("Missing Klaviyo List ID")
    console.error(error)
  }
}

export const subscribeToNewsletter = async (countryCode, email) => {
  const config = Object.assign(
    {},
    {
      g: getKlavyioListIds(countryCode),
      email: email
    }
  )

  const body = Object.keys(config).reduce((str, key) => {
    str.append(key, config[key])
    return str
  }, new URLSearchParams())

  return (
    await axios({
      url: "https://manage.kmail-lists.com/ajax/subscriptions/subscribe",
      method: "POST",
      headers: {
        "Access-Control-Allow-Headers": "*",
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8"
      },
      data: body
    })
  ).data
}
