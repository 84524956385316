const isProduction = process.env.NODE_ENV === "production"
const createLogger =
  (name) =>
  (...args) =>
    console.log(`events:${name}:`, ...args)

const CreateDispatcher = (name) => (windowVar) => {
  const logger = createLogger(name)
  logger.push = logger
  return isProduction ? windowVar || logger : logger
}

export default CreateDispatcher
