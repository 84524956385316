import React, { useContext } from "react"

export const PageContext = React.createContext({})
export const usePage = () => useContext(PageContext)

export const PageProvider = ({ pageContext, children }) => {
  return (
    <PageContext.Provider
      value={{
        ...pageContext
      }}
    >
      {children}
    </PageContext.Provider>
  )
}
