import React, { useContext } from "react"
import styled from "styled-components"
import { BrinkContext } from "../context/BrinkContext"
import Dinero from "dinero.js"

const Container = styled.div`
  color: ${(p) => p.theme.colors.black};
  display: flex;
  flex-direction: column;
  margin-left: 9rem;
  padding-top: 0.5rem;
`

const CurrentPrice = styled.div`
  margin: 0 0 0.8rem;
`

const OriginalPrice = styled.div`
  margin-left: 1rem;
  color: ${(p) => p.theme.colors.darkGrey};
  text-decoration: line-through;
`

const AddonText = styled.div`
  display: flex;
`

const Name = styled.div`
  margin: 0 0 0.8rem;
  text-transform: uppercase;
  font-size: 1.1rem;
`

const OptionValue = styled.div`
  font-size: 1.1rem;
  margin-left: 0.5rem;
`

const Price = styled.div`
  display: flex;
  font-size: 1.1rem;
  margin-top: 1rem;

  span {
    margin-right: 0.4rem;
  }
`

export default ({ addons, discounts }) => {
  const { currentStore } = useContext(BrinkContext)

  const toDinero = (amount, currencyUnit) => {
    return new Dinero({ amount: amount, currency: currencyUnit })
  }

  if (!addons) return null
  return (
    <>
      {addons?.map((addon) => {
        const discount = discounts.productDiscounts.find((d) => d.id === addon.id)
        const priceDinero = toDinero(addon.price[currentStore.currencyUnit], currentStore.currencyUnit)
        let discountDinero = null
        if (discount) {
          discountDinero = toDinero(discount.discountAmount[currentStore.currencyUnit] ?? 0, currentStore.currencyUnit)
        }
        const price = discountDinero ? priceDinero.subtract(discountDinero).toFormat() : null
        const originalPrice = priceDinero.toFormat()

        return (
          <Container key={addon.id}>
            <AddonText>
              <Name>{addon.name}</Name>
              {addon?.options?.length > 0 && addon?.options.map((o) => <OptionValue>"{o.value}"</OptionValue>)}
            </AddonText>
            <Price>
              {price ? (
                <>
                  <CurrentPrice>{price}</CurrentPrice>
                  <OriginalPrice>{originalPrice}</OriginalPrice>
                </>
              ) : (
                <CurrentPrice>{originalPrice}</CurrentPrice>
              )}
            </Price>
          </Container>
        )
      })}
    </>
  )
}
