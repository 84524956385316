import React, { useContext } from "react"
import styled from "styled-components"
import Dinero from "dinero.js"
import { BrinkContext } from "../context/BrinkContext"

const Prices = styled.div`
  display: flex;
  font-size: 1.5rem;
`

const Price = styled.span`
  display: block;
  width: 100%;
  margin-top: 0.2rem;
`

const OriginalPrice = styled.span`
  margin: ${(p) => (p.checkoutPrice ? "0.2rem 1rem 0" : 0)};
  color: ${(p) => p.theme.colors.darkGrey};
  text-decoration: line-through;
  font-size: ${(p) => (p.checkoutPrice ? "1.3rem" : "1.4rem")};
`

const getDiscountPrice = (discount, currencyUnit, priceAmount) => {
  const discountMoney = toDinero(discount.amount, currencyUnit)
  const price = toDinero(priceAmount, currencyUnit)
  return price.subtract(discountMoney)
}

export default ({ price, checkoutPrice, allDiscount = null, ...rest }) => {
  const { currentStore } = useContext(BrinkContext)
  const { currencyUnit } = currentStore
  const priceAmount = price.find((p) => p.currencyUnit === currencyUnit)
    ? price.find((p) => p.currencyUnit === currencyUnit).amount
    : 0
  const discount = allDiscount && allDiscount.find((d) => d.currencyUnit === currencyUnit)
  const priceWithDiscount = discount && getDiscountPrice(discount, currencyUnit, priceAmount)

  return (
    <Prices {...rest}>
      {discount && discount.amount !== 0 ? (
        <>
          <OriginalPrice checkoutPrice ={checkoutPrice}>{toDinero(priceAmount, currencyUnit).toFormat()}</OriginalPrice>
          <Price>{priceWithDiscount.toFormat()}</Price>
        </>
      ) : (
        <Price>{toDinero(priceAmount, currencyUnit).toFormat()}</Price>
      )}
    </Prices>
  )
}

const toDinero = (amount, currencyUnit) => {
  return new Dinero({ amount: amount, currency: currencyUnit })
}
